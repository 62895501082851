import { DeleteOutlined } from '@ant-design/icons';
import { Input, Modal, Form } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { deletePost } from '.';

export interface PostDeleteProps {
  id: string;
  title: string;
}

const PostDelete = ({ id, title }: PostDeleteProps) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const showModal = () => {
    form.resetFields();
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFinish = () => {
    deletePost(id).finally(() => {
      setIsModalOpen(false);
      navigate('/');
    });
  };

  return (
    <>
      <Link to={`#`} onClick={showModal}>
        <DeleteOutlined /> Delete
      </Link>
      <Modal
        title="Delete post"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form name="post" layout="vertical" form={form} onFinish={handleFinish}>
          <Form.Item
            label={`Input post's title (${title}) to confirm deletion`}
            name="title"
            rules={[
              { required: true, message: 'Please input the title!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('title') === title) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Wrong title!'));
                },
              }),
            ]}
          >
            <Input placeholder="Title" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PostDelete;

import {
  HomeOutlined,
  LoginOutlined,
  PlusOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Drawer, MenuProps } from 'antd';
import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { logout } from '../../firebase/services';
import { permissionState, userState } from '../../routes/store';
import { mainNavigationOpenState } from './store';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const mainItems: MenuProps['items'] = [
  getItem(<Link to={'/'}>Home page</Link>, '/', <HomeOutlined />),
];
const loginItems: MenuProps['items'] = [
  getItem(<Link to={'/login'}>Login</Link>, 'login', <LoginOutlined />),
];
const accountItems: MenuProps['items'] = [
  getItem('Account', 'sub2', <UserOutlined />, [
    getItem(<Link to={'/profile'}>Profile</Link>, '/profile'),
    getItem('Logout', 'logout'),
  ]),
];
const managementItems: MenuProps['items'] = [
  getItem(
    <Link to={'/management'}>Management</Link>,
    '/management',
    <SettingOutlined />
  ),
];

const MainNavigation = () => {
  const location = useLocation();

  const [user] = useRecoilState(userState);
  const [open, setOpen] = useRecoilState(mainNavigationOpenState);
  const [permission] = useRecoilState(permissionState);
  const [items, setItems] = useState([...mainItems, ...loginItems]);

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // const currentItems = [...mainItems];
    const currentItems = [
      ...mainItems,
      ...createDynamicItems(
        !!user?.uid,
        [
          getItem(
            <Link to={'/create'}>Create</Link>,
            '/create',
            <PlusOutlined />
          ),
        ],
        []
      ),
      ...createDynamicItems(
        permission === 'admin' || permission === 'moderator',
        managementItems,
        []
      ),
      ...createDynamicItems(!!user?.uid, accountItems, loginItems),
    ];
    setItems(currentItems);
  }, [permission, user]);

  const createDynamicItems = (
    condition: boolean,
    trueItems: MenuItem[],
    falseItems: MenuItem[]
  ) => {
    return condition ? trueItems : falseItems;
  };

  const onClose = () => {
    setOpen(false);
  };

  const onClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'logout':
        logout();
        break;
      default:
        break;
    }
  };

  return (
    <Drawer
      title="Menu"
      placement="left"
      width={256}
      headerStyle={{ padding: 16 }}
      bodyStyle={{ padding: 0 }}
      onClose={onClose}
      open={open}
    >
      <Menu
        onClick={onClick}
        style={{ width: '100%', height: '100%' }}
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={['sub1']}
        selectedKeys={[location.pathname]}
        mode="inline"
        items={items}
      />
    </Drawer>
  );
};

export default MainNavigation;

import { collection, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase/firebase';
import { getListData } from '../../firebase/services';
import Home from './Home';

import _ from 'lodash/fp';

export async function loader() {
  const posts = await getListData(
    query(
      collection(firestore, 'posts'),
      where('storyId', '==', ''),
      where('isDraft', '==', false),
      orderBy('modifiedTime', 'desc')
    )
  );
  const playablePosts = await getListData(
    query(
      collection(firestore, 'playablePosts'),
      where('storyId', '==', ''),
      where('isDraft', '==', false),
      orderBy('modifiedTime', 'desc')
    )
  );
  const stories = await getListData(
    query(
      collection(firestore, 'stories'),
      where('isDraft', '==', false),
      orderBy('modifiedTime', 'desc')
    )
  );
  return _.reverse(
    _.sortBy(['modifiedTime'])([
      ...posts.map((item) => ({ ...item, type: 'oneshot' })),
      ...playablePosts.map((item) => ({ ...item, type: 'playable' })),
      ...stories.map((item) => ({ ...item, type: 'story' })),
    ])
  );
}

export default Home;

import { Col, Row } from 'antd';
import { useLoaderData } from 'react-router-dom';
import GridView from '../GridView';
import PostCard from '../Post/PostCard';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  const posts: any = useLoaderData();

  const renderPosts = (post: any) => <PostCard post={post}></PostCard>;

  return (
    <div>
      <Helmet>
        <title>Diviby Blog</title>
        <meta name="description" content="Diviby Blog" />
        <meta property="og:title" content={' Diviby Blog'} />
        <meta property="og:description" content={'Play with the plot'} />
      </Helmet>
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 18, offset: 3 }}
          xl={{ span: 18, offset: 3 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <GridView items={posts} renderItem={renderPosts}></GridView>
        </Col>
      </Row>
    </div>
  );
};

export default Home;

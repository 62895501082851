import { collection, doc } from 'firebase/firestore';
import { LoaderFunctionArgs } from 'react-router-dom';
import { firestore } from '../../firebase/firebase';
import {
  addData,
  deleteData,
  getData,
  updateData,
} from '../../firebase/services';
import Post from './Post';
import PostForm from './PostForm';

export async function loader({ params }: LoaderFunctionArgs) {
  if (!params['postId']) {
    throw new Response('Not Found', { status: 404 });
  }
  const post = await getData(doc(firestore, 'posts', params['postId']));
  if (
    (!!post?.storyId &&
      post.storyId !== '' &&
      post.storyId !== params['storyId']) ||
    (!!params['storyId'] && post?.storyId !== params['storyId'])
  ) {
    throw new Response('Not Found', { status: 404 });
  }
  const author = await getData(doc(firestore, 'users', post!.authorId));
  return { ...post, author };
}
export async function addPost(values: any) {
  return await addData(collection(firestore, 'posts'), {
    ...values,
    createdTime: Date.now(),
    modifiedTime: Date.now(),
  });
}
export async function editPost(id: string, values: any) {
  return await updateData(doc(firestore, 'posts', id), {
    ...values,
    modifiedTime: Date.now(),
  });
}
export async function deletePost(id: string) {
  return await deleteData(doc(firestore, 'posts', id));
}

export { PostForm };
export default Post;

import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  where,
  writeBatch,
} from 'firebase/firestore';
import { LoaderFunctionArgs } from 'react-router-dom';
import { firestore } from '../../firebase/firebase';
import { getData, getListData } from '../../firebase/services';
import ProfileSetting from './ProfileSetting';
import Profile from './Profile';

import _ from 'lodash/fp';

export async function profileLoader({ params }: LoaderFunctionArgs) {
  if (!params['userId']) {
    return undefined;
  }
  return await getData(doc(firestore, 'users', params['userId']));
}

export async function updateAllUserPost(uid: string, data: any) {
  const batch = writeBatch(firestore);
  const listUserPosts = await getListData(
    query(
      collection(firestore, 'posts'),
      where('authorId', '==', uid),
      limit(100)
    )
  );

  listUserPosts.forEach((post) => {
    batch.update(doc(firestore, 'posts', post.id), {
      author: {
        displayName: data.displayName,
        photoURL: data.photoURL,
      },
    });
  });
  return await batch.commit();
}

export async function getAllUserPost(uid: string) {
  const posts = await getListData(
    query(
      collection(firestore, 'posts'),
      where('authorId', '==', uid),
      where('storyId', '==', ''),
      orderBy('modifiedTime', 'desc')
    )
  );
  const playablePosts = await getListData(
    query(
      collection(firestore, 'playablePosts'),
      where('authorId', '==', uid),
      where('storyId', '==', ''),
      orderBy('modifiedTime', 'desc')
    )
  );
  const stories = await getListData(
    query(
      collection(firestore, 'stories'),
      where('authorId', '==', uid),
      orderBy('modifiedTime', 'desc')
    )
  );
  return _.reverse<any>(
    _.sortBy<any>(['modifiedTime'])([
      ...posts.map((item) => ({ ...item, type: 'oneshot' })),
      ...playablePosts.map((item) => ({ ...item, type: 'playable' })),
      ...stories.map((item) => ({ ...item, type: 'story' })),
    ])
  );
}

export { ProfileSetting };
export default Profile;

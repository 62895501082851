import { collection, doc, orderBy, query, where } from 'firebase/firestore';
import { LoaderFunctionArgs } from 'react-router-dom';
import { firestore } from '../../firebase/firebase';
import {
  addData,
  deleteData,
  getData,
  getListData,
  updateData,
} from '../../firebase/services';
import { StoryType } from './model';
import Story from './Story';

export async function loader({ params }: LoaderFunctionArgs) {
  if (!params['storyId']) {
    throw new Response('Not Found', { status: 404 });
  }
  const story = await getData(doc(firestore, 'stories', params['storyId']));
  const posts = await getListData(
    query(
      collection(
        firestore,
        story?.type === StoryType.INTERACTIVE ? 'playablePosts' : 'posts'
      ),
      where('storyId', '==', params['storyId']),
      where('isDraft', '==', false),
      orderBy('createdTime', 'asc')
    )
  );
  const author = await getData(doc(firestore, 'users', story!.authorId));
  return {
    ...story,
    author,
    posts: posts.map((item) => ({
      ...item,
      type: story?.type === StoryType.INTERACTIVE ? 'playable' : 'normal',
    })),
  };
}
export async function addStory(values: any) {
  return await addData(collection(firestore, 'stories'), {
    ...values,
    createdTime: Date.now(),
    modifiedTime: Date.now(),
  });
}
export async function editStory(id: string, values: any) {
  return await updateData(doc(firestore, 'stories', id), {
    ...values,
    modifiedTime: Date.now(),
  });
}
export async function deleteStory(id: string) {
  return await deleteData(doc(firestore, 'stories', id));
}

export default Story;

import {
  BookOutlined,
  EditOutlined,
  PartitionOutlined,
  PlayCircleFilled,
  ThunderboltOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Card, Space, Typography } from 'antd';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import './style.scss';

const { Text, Title, Paragraph } = Typography;

const PostCard = ({ post, linkPrefix = '' }: any) => {
  const getLink = (type: string) => {
    switch (type) {
      case 'playable':
        return 'playable-posts';
      case 'story':
        return 'stories';
      case 'oneshot':
      default:
        return 'posts';
    }
  };
  return (
    <div key={post.id} className="post_card">
      <Link to={`${linkPrefix}/${getLink(post.type)}/${post.id}`}>
        <Card
          bordered={false}
          hoverable
          cover={
            <img
              style={{
                maxHeight: '20rem',
                minHeight: '16rem',
                objectFit: 'cover',
              }}
              src={post.cover || 'https://picsum.photos/id/104/400/360'}
              alt="logo"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = 'https://picsum.photos/id/104/400/360';
              }}
            ></img>
          }
          bodyStyle={{ padding: '1rem' }}
        >
          <div className="extend_icon">
            <Space>
              {post.mediaUrl && (
                <Text type="danger" code>
                  <PlayCircleFilled /> Music
                </Text>
              )}
              {post.isDraft && (
                <Text type="secondary" code>
                  <EditOutlined /> Draft
                </Text>
              )}
              {post.type === 'playable' && (
                <Text type="success" code>
                  <PartitionOutlined /> Interactive
                </Text>
              )}
              {post.type === 'oneshot' && (
                <Text type="success" code>
                  <ThunderboltOutlined /> Oneshot
                </Text>
              )}
              {post.type === 'story' && (
                <Text type="success" code>
                  <BookOutlined /> Story
                </Text>
              )}
            </Space>
          </div>
          <Title
            level={4}
            style={{ margin: 0 }}
            ellipsis={{ rows: 2, expandable: false }}
          >
            {post.title}
          </Title>
          <Paragraph ellipsis={{ rows: 2, expandable: false }}>
            <Text type="secondary" italic>
              {post.quote}
            </Text>
          </Paragraph>
          <Space className="author_container">
            {post?.author?.photoURL ? (
              <Avatar size={'small'} src={post.author.photoURL} />
            ) : (
              <Avatar size={'small'} icon={<UserOutlined />} />
            )}
            <Space wrap size={[8, 0]}>
              <Text strong>{post.author.displayName}</Text>
              <Text type="secondary" italic>
                {format(new Date(post.modifiedTime), 'dd/MM/yyyy HH:mm')}
              </Text>
            </Space>
          </Space>
        </Card>
      </Link>
    </div>
  );
};

export default PostCard;

import { User } from 'firebase/auth';
import { atom } from 'recoil';

export const userState = atom<Partial<User> | null>({
  key: 'user',
  default: null,
});

export const permissionState = atom<string | null>({
  key: 'permission',
  default: 'user',
});

export const isFullscreenState = atom<boolean>({
  key: 'fullscreen',
  default: false,
});

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLoaderData } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../routes/store';

type Permission = 'createPost' | 'updatePost' | 'deletePost';

interface ProtectedRouteProps {
  role: Permission;
}

const ProtectedRoute = ({
  role,
  children,
}: React.PropsWithChildren<ProtectedRouteProps>) => {
  const loaderData: any = useLoaderData();

  const [user] = useRecoilState(userState);
  const [isCorrect, setIsCorrect] = useState<string | null>(null);
  useEffect(() => {
    if (user) {
      if (checkPermission(role)) {
        handleCorrect();
      } else {
        handleIncorrect();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const checkPermission = (currentRole: Permission) => {
    switch (currentRole) {
      case 'createPost':
        return !!user?.uid;
      case 'updatePost':
        return user?.uid && user?.uid === loaderData?.authorId;
      default:
        return false;
    }
  };

  const handleCorrect = () => {
    setIsCorrect('correct');
  };
  const handleIncorrect = () => {
    setIsCorrect('error');
  };
  const renderError = () => (
    <div
      id="error-page"
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1>You don't have the right to access this page!</h1>
      <p>
        <Link to={'/'}>Back to home</Link>
      </p>
    </div>
  );

  if (isCorrect === 'correct') {
    return <>{children}</>;
  }
  if (isCorrect === 'error') {
    return renderError();
  }
  return null;
};

export default ProtectedRoute;

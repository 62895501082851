import {
  BookOutlined,
  PartitionOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import './style.scss';

const { Meta } = Card;

const CreatePage = () => {
  return (
    <div className="create_page_container">
      <div className="create_page">
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 20, offset: 2 }}
            lg={{ span: 18, offset: 3 }}
            xl={{ span: 18, offset: 3 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={8} lg={6}>
                <Link to={'/stories/new'}>
                  <Card
                    hoverable
                    cover={
                      <div className="icon_cover">
                        <BookOutlined />
                      </div>
                    }
                  >
                    <Meta title="Story" description="Create a story" />
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={8} lg={6}>
                <Link to={'/posts/new'}>
                  <Card
                    hoverable
                    cover={
                      <div className="icon_cover">
                        <ThunderboltOutlined />
                      </div>
                    }
                  >
                    <Meta
                      title="Oneshot"
                      description="Create a short story with one chapter"
                    />
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={8} lg={6}>
                <Link to={'/playable-posts/new'}>
                  <Card
                    hoverable
                    cover={
                      <div className="icon_cover">
                        <PartitionOutlined />
                      </div>
                    }
                  >
                    <Meta
                      title="Interactive Story"
                      description="Create a interactive story"
                    />
                  </Card>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreatePage;

import { Col, Drawer, Empty, Row } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { useWindowSize } from '../../../hook/useWindowSize';
import {
  ContentTreeNode,
  PlayableContentModel,
  ContentTreeLine,
  ContentNode,
  ContentNodeType,
} from '../playable.model';
import { createTree, createTreeLine } from './createTree.util';
import PlayableContentLine from './PlayableContentLine';
import PlayableContentNode from './PlayableContentNode';
import PlayableContentNodeForm, {
  PlayableContentNodeFormRef,
} from './PlayableContentNodeForm';

type CustomFormItemProps = {
  value?: PlayableContentModel;
  onChange?: (value: PlayableContentModel) => void;
};

const PlayableContentForm = ({ value, onChange }: CustomFormItemProps) => {
  const { width, height } = useWindowSize();
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [currentNode, setCurrentNode] = useState<ContentTreeNode | null>(null);
  const [preSelectedNode, setPreSelectedNode] =
    useState<ContentTreeNode | null>(null);
  const [tree, setTree] = useState<Map<string, ContentTreeNode>>(
    new Map<string, ContentTreeNode>()
  );
  const [lineMap, setLineMap] = useState<Map<string, ContentTreeLine>>(
    new Map<string, ContentTreeLine>()
  );
  const [listTreeNode, setListTreeNode] = useState<ContentTreeNode[]>([]);
  const nodeForm = useRef<PlayableContentNodeFormRef>();
  useEffect(() => {
    if (value?.nodes) {
      updateTree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (preSelectedNode === null || preSelectedNode === currentNode) {
      return;
    }
    if (currentNode === null) {
      setCurrentNode(preSelectedNode);
    }
    if (currentNode !== null) {
      nodeForm.current?.submitForm();
      if (nodeForm.current?.isFormSaved) {
        setCurrentNode(preSelectedNode);
      }
    }
    // setPreSelectedNode(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedNode]);

  const updateTree = () => {
    if (value?.nodes) {
      const createdTree = createTree(value.nodes);
      setTree(createdTree);
      setLineMap(createTreeLine(createdTree));
      setListTreeNode(Array.from(createdTree.values()));
    }
  };

  const handleNodeClick = (nodeId: string) => {
    const node = tree.get(nodeId);
    if (node) {
      setIsOpenSidebar(true);
      setPreSelectedNode(node);
    }
  };

  const handleNodeCreate = (node: ContentNode) => {
    value?.nodes.push(node);
  };

  const handleNodeDelete = (treeNode: ContentTreeNode) => {
    if (value?.nodes) {
      treeNode.parents.forEach((item) => {
        const parentsTreeNode = tree.get(item);
        if (parentsTreeNode) {
          switch (parentsTreeNode.node.type) {
            case ContentNodeType.CONTENT:
              parentsTreeNode.node.nextNode = '';
              break;
            case ContentNodeType.SELECTION:
              const option = parentsTreeNode.node.options?.find(
                (option) => option.nextNode === treeNode.node.id
              );
              if (option) {
                option.nextNode = '';
              }
              break;
            default:
              break;
          }
        }
      });
      value.nodes = value.nodes.filter((item) => item.id !== treeNode.node.id);
      onChange?.(value);
      updateTree();
    }
  };

  const handleNodeSave = (result: ContentTreeNode) => {
    if (value?.nodes) {
      const updateNode = value.nodes.find((node) => node.id === result.node.id);
      if (updateNode) {
        updateNode.content = result.node.content;
        updateNode.name = result.node.name;
        switch (result.node.type) {
          case ContentNodeType.ROOT:
          case ContentNodeType.CONTENT:
            updateNode.nextNode = result.node.nextNode;
            break;
          case ContentNodeType.SELECTION:
            updateNode.options = result.node.options;
            break;
          default:
            break;
        }
      }
      onChange?.(value);
      updateTree();
    }
  };

  const getStageSize = () => ({
    width: width || window.innerWidth,
    height: height || window.innerHeight,
  });

  const renderNodeForm = () => {
    return !!currentNode ? (
      <PlayableContentNodeForm
        key={currentNode.node.id}
        ref={nodeForm}
        treeNode={currentNode}
        listTreeNode={listTreeNode}
        onCreateNewNode={handleNodeCreate}
        onFinish={handleNodeSave}
        onDeleteNode={handleNodeDelete}
      ></PlayableContentNodeForm>
    ) : (
      <Empty />
    );
  };

  return (
    <>
      <Stage
        width={getStageSize().width}
        height={getStageSize().height}
        draggable
      >
        <Layer offsetY={-getStageSize().height / 2} offsetX={-200}>
          {Array.from(lineMap.values()).map((item) => (
            <PlayableContentLine
              treeLine={item}
              key={`${item.previousNode.node.id}-${item.nextNode.node.id}`}
            />
          ))}
        </Layer>
        <Layer offsetY={-getStageSize().height / 2} offsetX={-200}>
          {listTreeNode.map((item) => (
            <PlayableContentNode
              node={item.node}
              key={item.node.id}
              x={item.x}
              y={item.y}
              onClick={handleNodeClick}
              isFocused={item.node.id === currentNode?.node.id}
            />
          ))}
        </Layer>
      </Stage>
      <Drawer
        placement="right"
        onClose={() => setIsOpenSidebar(false)}
        mask={false}
        open={isOpenSidebar && !!currentNode}
        width={(width || window.innerWidth) >= 576 ? 576 : '100%'}
      >
        <Row>
          <Col span={24}>
            <div>{renderNodeForm()}</div>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default PlayableContentForm;

import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import { Link, useRouteLoaderData } from 'react-router-dom';
import './style.scss';
import { EditOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import StoryDelete from './StoryDelete';
import { useRecoilState } from 'recoil';
import { userState } from '../../routes/store';
import { format } from 'date-fns';

import { Helmet } from 'react-helmet-async';
import PostCard from '../Post/PostCard';
import { StoryType } from './model';

const { Text, Title } = Typography;

const Story = () => {
  const story: any = useRouteLoaderData('storyLoader');
  const [user] = useRecoilState(userState);

  if (!story) {
    return <Skeleton />;
  }

  return (
    <>
      <Helmet>
        <title>{`${story.title} | ${story.author.displayName} | Diviby Blog`}</title>
        <meta name="description" content={story.quote} />
      </Helmet>
      <Row className="story_container">
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 18, offset: 3 }}
          lg={{ span: 14, offset: 5 }}
          xl={{ span: 12, offset: 6 }}
          xxl={{ span: 8, offset: 8 }}
        >
          {!!user && user.uid === story.authorId && (
            <div className="action">
              <Space split={<Divider type="vertical" />}>
                <Link to={`/stories/${story.id}/edit`}>
                  <EditOutlined /> Edit
                </Link>
                <StoryDelete id={story.id} title={story.title} />
              </Space>
            </div>
          )}
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={'/'}>
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/profile/${story.authorId}`}>
                <UserOutlined />
                <span>{story.author.displayName}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={'#'}>
                <span>{story.title}</span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>

          <div className="main">
            <Title level={1} className="title">
              {story.title}
            </Title>
            <div>
              <Text type="secondary" italic>
                {story.quote}
              </Text>
            </div>
            <Row justify="space-between">
              <Col>
                <Space className="author_container">
                  <Link to={`/profile/${story.authorId}`}>
                    {story?.author?.photoURL ? (
                      <Avatar src={story.author.photoURL} />
                    ) : (
                      <Avatar icon={<UserOutlined />} />
                    )}
                  </Link>
                  <Space direction="vertical" size={0}>
                    <Link to={`/profile/${story.authorId}`}>
                      {story.author.displayName}
                    </Link>
                    <Text type="secondary">
                      {format(new Date(story.modifiedTime), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  </Space>
                </Space>
              </Col>
            </Row>

            {story.cover && (
              <div className="cover_image">
                <Image
                  width={'100%'}
                  src={story.cover}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = 'https://picsum.photos/id/104/1600/780';
                  }}
                />
                <Typography.Paragraph ellipsis>
                  <a href={story.cover} rel="noreferrer" target={'_blank'}>
                    {story.cover}
                  </a>
                </Typography.Paragraph>
              </div>
            )}
          </div>

          <div>
            <Row gutter={[16, 16]} align="stretch">
              {story.posts.map((post: any) => (
                <Col xs={24} sm={12} lg={8} key={post.id}>
                  <PostCard
                    post={post}
                    linkPrefix={`/stories/${story.id}`}
                  ></PostCard>
                </Col>
              ))}
              {!!user && user.uid === story.authorId && (
                <Col xs={24} sm={12} lg={8}>
                  <Card
                    hoverable
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Link
                      to={`${
                        story.type === StoryType.INTERACTIVE
                          ? 'playable-posts'
                          : 'posts'
                      }/new`}
                    >
                      <Button type="dashed" block>
                        + New chapter
                      </Button>
                    </Link>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Story;

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
} from 'firebase/auth';
const firebaseConfig = {
  apiKey: 'AIzaSyBv0pln-2oZRpvnSNNWLHbs-IuxeY2UPKg',
  authDomain: 'diviby-blog.firebaseapp.com',
  projectId: 'diviby-blog',
  storageBucket: 'diviby-blog.appspot.com',
  messagingSenderId: '811346949755',
  appId: '1:811346949755:web:1eb0f66fa5a113e2e494de',
  measurementId: 'G-MYNB5T9KRW',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

export const auth = getAuth(app);
auth.useDeviceLanguage();

export const firestore = getFirestore(app);

export const facebookProvider = new FacebookAuthProvider();
facebookProvider.setCustomParameters({
  display: 'popup',
});

export const googleProvider = new GoogleAuthProvider();
facebookProvider.setCustomParameters({
  login_hint: 'user@example.com',
});

import { collection, doc } from 'firebase/firestore';
import { LoaderFunctionArgs } from 'react-router-dom';
import { firestore } from '../../firebase/firebase';
import {
  addData,
  deleteData,
  getData,
  updateData,
} from '../../firebase/services';
import PlayablePost from './PlayablePost';
import PlayablePostForm from './PlayablePostForm';

export async function loader({ params }: LoaderFunctionArgs) {
  if (!params['postId']) {
    throw new Response('Not Found', { status: 404 });
  }
  const post = await getData(doc(firestore, 'playablePosts', params['postId']));
  if (
    (!!post?.storyId &&
      post.storyId !== '' &&
      post.storyId !== params['storyId']) ||
    (!!params['storyId'] && post?.storyId !== params['storyId'])
  ) {
    throw new Response('Not Found', { status: 404 });
  }
  const author = await getData(doc(firestore, 'users', post!.authorId));

  return { ...post, author };
}
export async function addPlayablePost(values: any) {
  return await addData(collection(firestore, 'playablePosts'), {
    ...values,
    createdTime: Date.now(),
    modifiedTime: Date.now(),
  });
}
export async function editPlayablePost(id: string, values: any) {
  return await updateData(doc(firestore, 'playablePosts', id), {
    ...values,
    modifiedTime: Date.now(),
  });
}
export async function deletePlayablePost(id: string) {
  return await deleteData(doc(firestore, 'playablePosts', id));
}

export { PlayablePostForm };
export default PlayablePost;

import { Line } from 'react-konva';
import { ContentNodeType, ContentTreeLine } from '../playable.model';
import { PlayableContentNodeDefaultSize } from './PlayableContentNode';
import { getColor, PlayableContentNodePaddingX } from './createTree.util';

type PlayableContentLineProps = {
  treeLine: ContentTreeLine;
};

const PlayableContentLine = ({ treeLine }: PlayableContentLineProps) => {
  const { previousNode, nextNode } = treeLine;
  const getPoints = () => {
    const startPoint = getStartPoint();
    const endPoint = getEndPoint();
    const middlePadding = (PlayableContentNodePaddingX * 1) / 2;
    return [
      ...startPoint,
      startPoint[0] + middlePadding,
      startPoint[1],
      endPoint[0] - middlePadding,
      endPoint[1],
      ...endPoint,
    ];
  };

  const getStartPoint = () => {
    const { x, y, node } = previousNode;
    switch (node.type) {
      case ContentNodeType.SELECTION:
        const nodeHeight = node.content
          ? PlayableContentNodeDefaultSize.contentHeight
          : PlayableContentNodeDefaultSize.noContentHeight;
        const index =
          node.options?.findIndex(
            (item) => item.nextNode === nextNode.node.id
          ) || 0;
        return [
          x +
            PlayableContentNodeDefaultSize.width +
            PlayableContentNodeDefaultSize.padding,
          y +
            nodeHeight +
            PlayableContentNodeDefaultSize.optionHeight * index +
            (PlayableContentNodeDefaultSize.optionHeight / 2 -
              PlayableContentNodeDefaultSize.padding / 4),
        ];
      default:
        return [
          x + PlayableContentNodeDefaultSize.width,
          y + PlayableContentNodeDefaultSize.contentHeight / 2,
        ];
    }
  };
  const getEndPoint = () => [
    nextNode.x - PlayableContentNodeDefaultSize.padding / 2,
    nextNode.y,
  ];

  return (
    <Line
      points={getPoints()}
      stroke={getColor(previousNode.node.type)}
      strokeWidth={4}
      bezier={true}
    ></Line>
  );
};

export default PlayableContentLine;

import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from 'firebase/auth';
import {
  addDoc,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  Query,
  setDoc,
  UpdateData,
  updateDoc,
  WithFieldValue,
} from 'firebase/firestore';
import { auth, facebookProvider, firestore, googleProvider } from './firebase';

export async function getListData<T>(
  query: Query<T>
): Promise<({ id: string } & T)[]> {
  const posts = await getDocs<T>(query);
  const result: ({ id: string } & T)[] = [];
  posts.forEach((doc) => {
    result.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return result;
}

export async function getData<T>(
  query: DocumentReference<T>
): Promise<({ id: string } & T) | null> {
  const post = await getDoc<T>(query);
  if (post.exists()) {
    return {
      id: post.id,
      ...post.data(),
    };
  } else {
    throw new Response('Not Found', { status: 404 });
  }
}

export async function addData<T>(
  documentRef: CollectionReference<T>,
  data: WithFieldValue<T>
): Promise<DocumentReference<T>> {
  return await addDoc<T>(documentRef, data);
}

export async function setData<T>(
  documentRef: DocumentReference<T>,
  data: WithFieldValue<T>
): Promise<void> {
  return await setDoc<T>(documentRef, data);
}

export async function updateData<T>(
  documentRef: DocumentReference<T>,
  data: UpdateData<T>
): Promise<void> {
  return await updateDoc<T>(documentRef, data);
}

export async function deleteData(
  documentRef: DocumentReference
): Promise<void> {
  return await deleteDoc(documentRef);
}

export async function createUser(
  email: string,
  password: string,
  displayName: string
) {
  return createUserWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      const { user } = userCredential;
      return updateProfile(user, {
        displayName,
      });
    }
  );
}

export async function login(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

export async function loginWithFacebook() {
  return signInWithPopup(auth, facebookProvider);
}

export async function loginWithGoogle() {
  return signInWithPopup(auth, googleProvider);
}

export async function resetPasswordEmail(email: string) {
  return sendPasswordResetEmail(auth, email);
}

export async function logout() {
  return signOut(auth);
}

export async function getUserData(uid: string) {
  return getDoc(doc(firestore, 'users', uid));
}

export async function updateUserProfile(data: { [key: string]: string }) {
  if (!auth.currentUser) {
    throw new Error('Has no user');
  }
  updateData(doc(firestore, 'users', auth.currentUser.uid), {
    displayName: data.displayName || '',
    photoURL: data.photoURL || '',
    description: data.description || '',
  });
  return updateProfile(auth.currentUser, {
    displayName: data.displayName,
    photoURL: data.photoURL,
  });
}

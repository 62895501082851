import { Button, Divider, Form, Input, Select, Space, Tag } from 'antd';
import {
  ContentNode,
  ContentNodeType,
  ContentTreeNode,
} from '../playable.model';
import { getColor } from './createTree.util';

import '../style.scss';
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { NamePath } from 'antd/lib/form/interface';
import ReactQuill from 'react-quill';
import { forwardRef, useImperativeHandle, useState } from 'react';

type PlayableContentNodeFormProps = {
  treeNode: ContentTreeNode;
  listTreeNode: ContentTreeNode[];
  onFinish?: (value: ContentTreeNode) => void;
  onCreateNewNode?: (node: ContentNode) => void;
  onDeleteNode?: (node: ContentTreeNode) => void;
};

export type PlayableContentNodeFormRef = {
  submitForm: () => void;
  isFormSaved: () => boolean;
};

const PlayableContentNodeForm = forwardRef<
  PlayableContentNodeFormRef | undefined,
  PlayableContentNodeFormProps
>(
  (
    { treeNode, listTreeNode, onFinish, onCreateNewNode, onDeleteNode },
    forwardedRef
  ) => {
    const [form] = Form.useForm();
    const [saved, setSaved] = useState(true);

    useImperativeHandle(forwardedRef, () => ({
      submitForm: () => {
        if (!saved) {
          submit();
        }
      },
      isFormSaved: () => saved,
    }));

    const handleNameBlur = () => {
      const inputValue = form.getFieldValue(['node', 'name']);
      if (inputValue && inputValue.trim() !== treeNode.node.name) {
        submit();
      }
    };
    const handleContentBlur = () => {
      const inputValue = form.getFieldValue(['node', 'content']);
      if (inputValue && inputValue.trim() !== treeNode.node.content) {
        submit();
      }
    };
    const handleNextNodeSelect = (value: string, namepath: NamePath) => {
      switch (value) {
        case 'createContent':
          const createContentId = `c${Date.now()}`;
          onCreateNewNode?.({
            id: createContentId,
            name: 'New Content',
            type: ContentNodeType.CONTENT,
            content: '',
            nextNode: '',
          });
          form.setFieldValue(namepath, createContentId);
          break;
        case 'createSelection':
          const createSelectionId = `s${Date.now()}`;
          onCreateNewNode?.({
            id: createSelectionId,
            name: 'New Selection',
            type: ContentNodeType.SELECTION,
            content: '',
          });
          form.setFieldValue(namepath, createSelectionId);
          break;
        case 'createEnd':
          const createEndId = `e${Date.now()}`;
          onCreateNewNode?.({
            id: createEndId,
            name: 'End',
            type: ContentNodeType.END,
            content: '',
          });
          form.setFieldValue(namepath, createEndId);
          break;
        default:
          break;
      }
      submit();
    };

    const submit = () => {
      form.submit();
      setSaved(true);
    };
    const getNextNodeOptions = () => {
      const actionOptions = [{ value: '', label: 'None' }];
      if (!treeNode.node.nextNode) {
        actionOptions.push(
          { value: 'createContent', label: 'Create new content' },
          { value: 'createSelection', label: 'Create new selection' },
          { value: 'createEnd', label: 'Create end node' }
        );
      }

      return [
        ...actionOptions,
        ...listTreeNode
          .filter((item) => item.node.type !== ContentNodeType.ROOT)
          .map((item) => ({
            value: item.node.id,
            label: `${item.node.name} - ${item.node.type}`,
          })),
      ];
    };

    const deleteNode = () => {
      onDeleteNode?.(treeNode);
    };

    return (
      <div className="node_form_container">
        <div>
          <Form
            name="node"
            layout="vertical"
            onFinish={onFinish}
            initialValues={treeNode}
            autoComplete="off"
            form={form}
            onValuesChange={() => setSaved(false)}
          >
            <Tag color={getColor(treeNode.node.type)}>{treeNode.node.type}</Tag>
            <br />
            <Form.Item name={['node', 'id']} hidden>
              <Input readOnly />
            </Form.Item>
            <Form.Item name={['node', 'type']} hidden>
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name={['node', 'name']}
              rules={[
                { required: true, message: 'Please input the name!' },
                { max: 150, message: 'Maximum 150 characters' },
              ]}
            >
              <Input
                className="name_input"
                placeholder="Name"
                maxLength={150}
                size="large"
                bordered={false}
                onBlur={handleNameBlur}
                onPressEnter={handleNameBlur}
                suffix={<EditOutlined />}
                readOnly={treeNode.node.type === ContentNodeType.ROOT}
              />
            </Form.Item>

            <Form.Item
              name={['node', 'content']}
              rules={[{ max: 5000, message: 'Maximum 150 characters' }]}
              hidden={treeNode.node.type === ContentNodeType.ROOT}
            >
              <ReactQuill
                className="text_editor border"
                theme="bubble"
                placeholder="Input content here"
                onBlur={handleContentBlur}
              ></ReactQuill>
            </Form.Item>
            {[ContentNodeType.CONTENT, ContentNodeType.ROOT].includes(
              treeNode.node.type
            ) && (
              <Form.Item label="Next to" name={['node', 'nextNode']}>
                <Select
                  showSearch
                  placeholder="Node"
                  style={{ width: '100%' }}
                  options={getNextNodeOptions()}
                  onChange={(val) =>
                    handleNextNodeSelect(val, ['node', 'nextNode'])
                  }
                ></Select>
              </Form.Item>
            )}
            {treeNode.node.type === ContentNodeType.SELECTION && (
              <Form.List name={['node', 'options']}>
                {(fields, { add, remove }) => (
                  <>
                    <Divider orientation="left">Options</Divider>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ width: '100%', marginBottom: '1rem' }}
                        align="baseline"
                        direction="horizontal"
                      >
                        <div>
                          <Form.Item {...restField} name={[name, 'content']}>
                            <Input placeholder="Content"></Input>
                          </Form.Item>
                          <Form.Item {...restField} name={[name, 'nextNode']}>
                            <Select
                              showSearch
                              placeholder="Node"
                              options={getNextNodeOptions()}
                              onChange={(val) =>
                                handleNextNodeSelect(val, [
                                  'node',
                                  'options',
                                  name,
                                  'nextNode',
                                ])
                              }
                            ></Select>
                          </Form.Item>
                        </div>
                        <Button
                          disabled={
                            form.getFieldValue([
                              'node',
                              'options',
                              name,
                              'nextNode',
                            ]) &&
                            form.getFieldValue([
                              'node',
                              'options',
                              name,
                              'nextNode',
                            ]) !== ''
                          }
                          onClick={() => remove(name)}
                        >
                          <MinusCircleOutlined />
                        </Button>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add option
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
            {treeNode.node.type !== ContentNodeType.ROOT && (
              <Form.Item>
                <Button
                  danger
                  htmlType="button"
                  disabled={treeNode.children.length > 0}
                  onClick={deleteNode}
                >
                  Delete this
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    );
  }
);

export default PlayableContentNodeForm;

import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Typography,
} from 'antd';
import { Link, useRouteLoaderData } from 'react-router-dom';
import { useLoaderData } from 'react-router-dom';
import './style.scss';
import {
  CaretRightOutlined,
  EditOutlined,
  HomeOutlined,
  PauseOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { userState } from '../../routes/store';
import { format } from 'date-fns';
import ReactPlayer from 'react-player/lazy';
import { useState } from 'react';
import PlayablePostDelete from './PlayablePostDelete';
import PlayableContent from './PlayableContent';

import { Helmet } from 'react-helmet-async';

const { Text, Title } = Typography;

const PlayablePost = () => {
  const post: any = useLoaderData();
  const story: any = useRouteLoaderData('storyLoader');
  const [user] = useRecoilState(userState);
  const [isPlay, setIsPlay] = useState(false);

  const handlePlaying = () => {
    setIsPlay(!isPlay);
  };

  return (
    <>
      <Helmet>
        <title>{`${post.title} | ${post.author.displayName} | Diviby Blog`}</title>
        <meta name="description" content={post.quote} />
        <meta
          property="og:title"
          content={`${post.title} | ${post.author.displayName} | Diviby Blog`}
        />
        <meta property="og:description" content={post.quote} />
        <meta property="og:image" content={post.cover} />
        <meta property="og:url" content="https://blog.diviby.com/" />
      </Helmet>
      <Row className="post_container">
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 18, offset: 3 }}
          lg={{ span: 14, offset: 5 }}
          xl={{ span: 12, offset: 6 }}
          xxl={{ span: 8, offset: 8 }}
        >
          {!!user && user.uid === post.authorId && (
            <div className="action">
              <Space split={<Divider type="vertical" />}>
                <Link
                  to={`${
                    !!story ? `/stories/${story.id}` : ''
                  }/playable-posts/${post.id}/edit`}
                >
                  <EditOutlined /> Edit
                </Link>
                <PlayablePostDelete id={post.id} title={post.title} />
              </Space>
            </div>
          )}
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={'/'}>
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/profile/${post.authorId}`}>
                <UserOutlined />
                <span>{post.author.displayName}</span>
              </Link>
            </Breadcrumb.Item>
            {post.storyId !== '' && !!story && (
              <Breadcrumb.Item>
                <Link to={`/stories/${post.storyId}`}>
                  <span>{story.title}</span>
                </Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>
              <Link to={'#'}>
                <span>{post.title}</span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="main">
            <Title level={1} className="title">
              {post.title}
            </Title>
            <div>
              <Text type="secondary" italic>
                {post.quote}
              </Text>
            </div>
            <Row justify="space-between">
              <Col>
                <Space className="author_container">
                  <Link to={`/profile/${post.authorId}`}>
                    {post?.author?.photoURL ? (
                      <Avatar src={post.author.photoURL} />
                    ) : (
                      <Avatar icon={<UserOutlined />} />
                    )}
                  </Link>
                  <Space direction="vertical" size={0}>
                    <Link to={`/profile/${post.authorId}`}>
                      {post.author.displayName}
                    </Link>
                    <Text type="secondary">
                      {format(new Date(post.modifiedTime), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  </Space>
                </Space>
              </Col>
              <Col>
                {post.mediaUrl && (
                  <Button
                    size="large"
                    type="primary"
                    shape="circle"
                    icon={isPlay ? <PauseOutlined /> : <CaretRightOutlined />}
                    onClick={handlePlaying}
                  ></Button>
                )}
              </Col>
            </Row>

            {post.cover && (
              <div className="cover_image">
                <Image
                  width={'100%'}
                  src={post.cover}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = 'https://picsum.photos/id/104/1600/780';
                  }}
                />
                <Typography.Paragraph ellipsis>
                  <a href={post.cover} rel="noreferrer" target={'_blank'}>
                    {post.cover}
                  </a>
                </Typography.Paragraph>
              </div>
            )}
            <PlayableContent content={post.content}></PlayableContent>
            {post.mediaUrl && (
              <>
                <Row justify="center">
                  <ReactPlayer
                    url={post.mediaUrl}
                    playing={isPlay}
                  ></ReactPlayer>
                </Row>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PlayablePost;

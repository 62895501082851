import './style.scss';

const Logo = ({ size = '2rem' }: any) => {
  return (
    <div className="logo">
      <h1 style={{ fontSize: size }}>Diviby</h1>
    </div>
  );
};

export default Logo;

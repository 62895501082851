import { LinkedinOutlined } from '@ant-design/icons';
import { Divider, Space, Typography } from 'antd';
import { useRecoilState } from 'recoil';
import { isFullscreenState } from '../../routes/store';
import './style.scss';

const { Link } = Typography;
const Footer = () => {
  const [isFullscreen] = useRecoilState(isFullscreenState);

  return (
    <div className={`footer_container ${isFullscreen ? 'fullscreen' : ''}`}>
      <Space split={<Divider type="vertical" />}>
        <div className="footer_item">
          <Link href="#">Diviby</Link>
        </div>
        <div className="footer_item">
          <Link href="https://www.linkedin.com/in/th%E1%BA%AFng-mai-128276187/">
            Created by Thang Mai <LinkedinOutlined />
          </Link>
        </div>
      </Space>
    </div>
  );
};

export default Footer;

import {
  CloseOutlined,
  LoginOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Col, Popover, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { logout } from '../../firebase/services';
import { isFullscreenState, userState } from '../../routes/store';
import Logo from '../Logo';
import { mainNavigationOpenState } from '../MainNavigation/store';
import './style.scss';

const UserPopoverContent = () => {
  return (
    <Space direction="vertical">
      <Link to={'/profile'}>Profile</Link>
      <Link
        to={'#'}
        onClick={() => {
          logout();
        }}
      >
        Logout
      </Link>
    </Space>
  );
};

const Header = () => {
  const [user] = useRecoilState(userState);
  const [open, setOpen] = useRecoilState(mainNavigationOpenState);
  const [isFullscreen] = useRecoilState(isFullscreenState);

  const showDrawer = () => {
    setOpen(true);
  };
  return (
    <Row
      wrap={false}
      align="middle"
      className={`header_container ${isFullscreen ? 'fullscreen' : ''}`}
    >
      <Col flex="none">
        <div className="header_item">
          <Button
            type="primary"
            shape="circle"
            icon={open ? <CloseOutlined /> : <MenuOutlined />}
            onClick={showDrawer}
          />
        </div>
      </Col>
      {!isFullscreen && (
        <>
          <Col flex="auto">
            <div className="logo_container">
              <Link to={'/'}>
                <Logo></Logo>
              </Link>
            </div>
          </Col>
          <Col flex="none">
            <div className="header_item">
              {!!user ? (
                <Popover
                  placement="bottomRight"
                  content={<UserPopoverContent />}
                  trigger="click"
                  arrowPointAtCenter
                >
                  {user.photoURL ? (
                    <Avatar src={user.photoURL} />
                  ) : (
                    <Avatar icon={<UserOutlined />} />
                  )}
                </Popover>
              ) : (
                <Link to={'/login'}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<LoginOutlined />}
                  />
                </Link>
              )}
            </div>
          </Col>
        </>
      )}
    </Row>
  );
};

export default Header;

import { Circle, Group, Rect, Text } from 'react-konva';
import { ContentNode, ContentNodeType } from '../playable.model';
import { getColor } from './createTree.util';

type PlayableContentNodeProps = {
  node: ContentNode;
  x?: number;
  y?: number;
  fontSize?: number;
  isFocused?: boolean;
  onClick?: (nodeId: string) => void;
};

const createPlayableContentNodeSize = (
  fontSize: number,
  hasContent: boolean
) => {
  const padding = fontSize;
  const width = fontSize * 18;
  const contentHeight = fontSize * 5 + padding * 3.5;
  const noContentHeight = fontSize * 1 + padding * 3;
  const height = hasContent ? contentHeight : noContentHeight;
  const optionHeight = fontSize + padding * 1.5;
  return {
    fontSize,
    padding,
    width,
    height,
    contentHeight,
    noContentHeight,
    optionHeight,
  };
};

export const PlayableContentNodeDefaultSize = createPlayableContentNodeSize(
  14,
  true
);

const PlayableContentNode = ({
  node,
  x = 0,
  y = 0,
  fontSize = PlayableContentNodeDefaultSize.fontSize,
  isFocused,
  onClick = (_nodeId: string) => void 0,
}: PlayableContentNodeProps) => {
  const { padding, width, height, optionHeight } =
    createPlayableContentNodeSize(
      fontSize,
      node.content.length > 0 || node.type === ContentNodeType.ROOT
    );
  const typeWidth = 100;

  return (
    <Group
      x={x}
      y={y}
      onClick={() => onClick(node.id)}
      onTap={() => onClick(node.id)}
    >
      <Rect
        x={0}
        y={0}
        width={width}
        height={height + optionHeight * (node.options?.length || 0)}
        fill="white"
        shadowColor={isFocused ? getColor(node.type) : undefined}
        shadowBlur={isFocused ? 10 : undefined}
      />
      <Rect
        x={-fontSize / 2 - padding / 2}
        y={-fontSize / 2 - padding / 2}
        width={typeWidth}
        height={fontSize + padding}
        fill={getColor(node.type)}
        cornerRadius={fontSize + padding}
        shadowColor={isFocused ? getColor(node.type) : undefined}
        shadowBlur={isFocused ? 5 : 1}
      />
      <Text
        x={-fontSize / 2 - padding / 2}
        y={-fontSize / 2 - padding / 2 + 2}
        text={node.type.valueOf()}
        width={typeWidth}
        height={fontSize + padding}
        fontSize={fontSize}
        padding={padding / 2}
        fontStyle="bold"
        fill="white"
        align="center"
      ></Text>
      <Text
        y={padding}
        text={node.name}
        width={width}
        height={fontSize * 1 + padding * 2}
        fontSize={fontSize}
        fontStyle="bold"
        padding={padding}
        ellipsis
      ></Text>
      <Text
        y={fontSize + padding * 2}
        text={node.content.replace(/<\/?[^>]+(>|$)/g, '')}
        width={width}
        height={fontSize * 4 + padding * 2}
        fontSize={fontSize}
        padding={padding}
        ellipsis
      ></Text>
      {node.nextNode && (
        <Circle
          x={width}
          y={height / 2}
          radius={6}
          fill={getColor(node.type)}
        ></Circle>
      )}
      {node.options?.map((item, index) => (
        <Group key={index} x={0} y={height + index * optionHeight}>
          <Rect width={width} height={optionHeight} fill="white" />
          <Rect
            x={padding}
            y={0}
            width={width}
            height={optionHeight - padding / 2}
            fill={getColor(node.type)}
            cornerRadius={optionHeight - padding / 2}
            shadowColor="#888888"
            shadowBlur={1}
          />
          <Text
            x={padding}
            y={0}
            text={`${index + 1}. ${item.content}`}
            width={width}
            height={optionHeight - padding / 2}
            fontSize={fontSize}
            padding={padding / 2}
            fill="white"
            ellipsis
          ></Text>
        </Group>
      ))}
    </Group>
  );
};

export default PlayableContentNode;

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './App.less';
import Management from './component/Admin';
import Home, { loader as homeLoader } from './component/Home';
import Login from './component/Login';
import PlayablePost, {
  PlayablePostForm,
  loader as playablePostLoader,
} from './component/PlayablePost';
import Post, { loader as postLoader, PostForm } from './component/Post';
import PrivacyPolicy from './component/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './component/PrivacyPolicy/TermsConditions';
import Profile, { profileLoader, ProfileSetting } from './component/Profile';
import ProtectedRoute from './component/ProtectedRoute';
import ErrorPage from './ErrorPage';
import Root from './routes/root';
import { HelmetProvider } from 'react-helmet-async';
import CreatePage from './component/CreatePage';
import StoryForm from './component/Story/StoryForm';
import Story, { loader as storyLoader } from './component/Story';
import StoryPageContainer from './component/Story/StoryPageContainer';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root></Root>,
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        path: 'stories/:storyId/edit',
        element: (
          <ProtectedRoute role="updatePost">
            <StoryForm key={'edit'} />
          </ProtectedRoute>
        ),
        loader: storyLoader,
      },
      {
        path: 'stories/:storyId',
        element: <StoryPageContainer />,
        loader: storyLoader,
        id: 'storyLoader',
        children: [
          {
            path: 'posts/:postId/edit',
            element: (
              <ProtectedRoute role="updatePost">
                <PostForm key={'edit'} />
              </ProtectedRoute>
            ),
            loader: postLoader,
          },
          {
            path: 'posts/:postId',
            element: <Post />,
            loader: postLoader,
          },
          {
            path: 'posts/new',
            element: (
              <ProtectedRoute role="createPost">
                <PostForm key={'new'} />
              </ProtectedRoute>
            ),
          },
          {
            path: 'playable-posts/:postId/edit',
            element: (
              <ProtectedRoute role="updatePost">
                <PlayablePostForm key={'edit'} />
              </ProtectedRoute>
            ),
            loader: playablePostLoader,
          },
          {
            path: 'playable-posts/:postId',
            element: <PlayablePost />,
            loader: playablePostLoader,
          },
          {
            path: 'playable-posts/new',
            element: (
              <ProtectedRoute role="createPost">
                <PlayablePostForm key={'new'} />
              </ProtectedRoute>
            ),
          },
          {
            path: '',
            element: <Story />,
          },
        ],
      },
      {
        path: 'stories/new',
        element: (
          <ProtectedRoute role="createPost">
            <StoryForm key={'new'} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'posts/:postId/edit',
        element: (
          <ProtectedRoute role="updatePost">
            <PostForm key={'edit'} />
          </ProtectedRoute>
        ),
        loader: postLoader,
      },
      {
        path: 'posts/:postId',
        element: <Post />,
        loader: postLoader,
      },
      {
        path: 'posts/new',
        element: (
          <ProtectedRoute role="createPost">
            <PostForm key={'new'} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'playable-posts/:postId/edit',
        element: (
          <ProtectedRoute role="updatePost">
            <PlayablePostForm key={'edit'} />
          </ProtectedRoute>
        ),
        loader: playablePostLoader,
      },
      {
        path: 'playable-posts/:postId',
        element: <PlayablePost />,
        loader: playablePostLoader,
      },
      {
        path: 'playable-posts/new',
        element: (
          <ProtectedRoute role="createPost">
            <PlayablePostForm key={'new'} />
          </ProtectedRoute>
        ),
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute role="createPost">
            <CreatePage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/profile/:userId',
        element: <Profile />,
        loader: profileLoader,
      },
      {
        path: '/profile/settings',
        element: <ProfileSetting />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/management',
        element: <Management />,
      },
      {
        path: '',
        element: <Home />,
        loader: homeLoader,
      },
    ],
  },
  {
    path: '/login',
    element: <Login></Login>,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy></PrivacyPolicy>,
  },
  {
    path: '/terms-and-conditions',
    element: <TermsConditions></TermsConditions>,
  },
]);

const helmetContext = {};

function App() {
  return (
    <>
      <RecoilRoot>
        <HelmetProvider context={helmetContext}>
          <RouterProvider router={router}></RouterProvider>
        </HelmetProvider>
      </RecoilRoot>
    </>
  );
}

export default App;

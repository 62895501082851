import { BackTop } from 'antd';
import { onAuthStateChanged } from 'firebase/auth';
import { doc } from 'firebase/firestore';
import { useEffect } from 'react';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import Footer from '../component/Footer';
import Header from '../component/Header';
import MainContainer from '../component/MainContainer';
import MainNavigation from '../component/MainNavigation';
import { auth, firestore } from '../firebase/firebase';
import { getUserData, setData, updateData } from '../firebase/services';
import { isFullscreenState, permissionState, userState } from './store';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [pathname]);

  return null;
}
function FullscreenHandle() {
  const { pathname } = useLocation();
  const setIsFullscreen = useSetRecoilState(isFullscreenState);

  const fullscreenPage = [
    '/playable-posts/:postId/edit',
    '/playable-posts/new',
  ];

  useEffect(() => {
    if (fullscreenPage.some((pattern) => matchPath(pattern, pathname))) {
      setIsFullscreen(true);
    } else {
      setIsFullscreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
}

export default function Root() {
  const setUser = useSetRecoilState(userState);
  const setPermission = useSetRecoilState(permissionState);
  useEffect(() => {
    const observer = onAuthStateChanged(auth, (user) => {
      if (user) {
        const { email, uid } = user;
        const displayName =
          user.displayName ||
          user.providerData.find((data) => data.displayName)?.displayName ||
          'Default Name';
        const photoURL =
          user.photoURL ||
          user.providerData.find((data) => data.photoURL)?.photoURL ||
          '';
        getUserData(uid)
          .then((user) => {
            if (user.exists()) {
              const currentProfile = user.data();
              if (!(currentProfile.photoURL.length > 0)) {
                updateData(doc(firestore, 'users', uid), {
                  photoURL,
                });
              }
              setUser({ ...currentProfile, uid, email, photoURL });
              setPermission(currentProfile?.permission || 'user');
            } else {
              setData(doc(firestore, 'users', uid), {
                displayName,
                photoURL,
                email,
                permission: 'user',
              });
              setUser({ uid, displayName, photoURL, email });
            }
          })
          .catch(() => {
            setUser({ uid, displayName, photoURL, email });
            setPermission(null);
          });
      } else {
        setUser(null);
        setPermission(null);
      }
    });
    return () => {
      observer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <MainNavigation></MainNavigation>
      <Header></Header>
      <MainContainer>
        <Outlet></Outlet>
        <ScrollToTop />
        <FullscreenHandle />
      </MainContainer>
      <Footer></Footer>
      <BackTop />
    </>
  );
}

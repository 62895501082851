export enum ContentNodeType {
  ROOT = 'Root',
  SELECTION = 'Selection',
  CONDITION = 'Condition',
  END = 'End',
  CONTENT = 'Content',
}
export type PlayableContentModel = {
  globalVariables: any;
  nodes: ContentNode[];
};
export type Option = {
  content: string;
  nextNode: string;
};

export type ContentNode = {
  id: string;
  name: string;
  type: ContentNodeType;
  content: string;
  options?: Option[];
  nextNode?: string;
};

export type ContentTreeNode = {
  parents: string[];
  children: string[];
  level: number;
  wideLevel: number;
  node: ContentNode;
  x: number;
  y: number;
};

export type ContentTreeLine = {
  previousNode: ContentTreeNode;
  nextNode: ContentTreeNode;
};

export const demoContent: PlayableContentModel = {
  globalVariables: {},
  nodes: [
    {
      id: 'root',
      name: 'Root',
      type: ContentNodeType.ROOT,
      content: 'Title in here',
      nextNode: 'c1',
    },
    {
      id: 'c1',
      name: 'node c1',
      type: ContentNodeType.CONTENT,
      content:
        'Vào một đêm trăng thanh gió mát, bản giao hưởng gồm giọng ca của cóc, cùng tiếng kéo nhị của dế hòa cùng tiếng vỗ muỗi đen đét của An. An đang thực hiện tiếng gọi của thiên nhiên',
      nextNode: 's1',
    },
    {
      id: 's1',
      name: 'node s1',
      type: ContentNodeType.SELECTION,
      content: '',
      options: [
        { content: 'dưới gốc cây', nextNode: 'c2' },
        { content: 'sau bụi chuối', nextNode: 'c3' },
        { content: 'gốc đa', nextNode: 'c5' },
      ],
    },
    {
      id: 'c2',
      name: 'node c2',
      type: ContentNodeType.CONTENT,
      content: 'Một con rắn bò qua...',
      nextNode: '',
    },
    {
      id: 'c3',
      name: 'node c3',
      type: ContentNodeType.CONTENT,
      content: 'Nghe đâu đó tiếng rên...',
      nextNode: 's2',
    },
    {
      id: 's2',
      name: 'node s2',
      type: ContentNodeType.SELECTION,
      content: 'Cậu quyết định...',
      options: [
        { content: 'đi vòng ra sau xem', nextNode: 'c4' },
        { content: 'tiếp tục đái', nextNode: 'c8' },
      ],
    },
    {
      id: 'c4',
      name: 'node c4',
      type: ContentNodeType.CONTENT,
      content: 'Thấy hai bóng người',
      nextNode: '',
    },
    {
      id: 'c5',
      name: 'node c5',
      type: ContentNodeType.CONTENT,
      content: 'Chú bộ đội',
      nextNode: 's3',
    },
    {
      id: 's3',
      name: 'node s3',
      type: ContentNodeType.SELECTION,
      content: 'Đánh tay đôi',
      options: [
        { content: 'đấm', nextNode: 'c6' },
        { content: 'đá', nextNode: '' },
      ],
    },
    {
      id: 'c6',
      name: 'node c6',
      type: ContentNodeType.CONTENT,
      content: 'Chú bộ đội',
      nextNode: '',
    },
    {
      id: 'c7',
      name: 'node c7',
      type: ContentNodeType.CONTENT,
      content: 'Chú bộ đội',
      nextNode: '',
    },
    {
      id: 'c8',
      name: 'node c8',
      type: ContentNodeType.CONTENT,
      content: 'Chú bộ đội',
      nextNode: '',
    },
  ],
};

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography } from 'antd';
import DOMPurify from 'dompurify';
import { useEffect, useLayoutEffect, useState } from 'react';
import {
  ContentNode,
  ContentNodeType,
  PlayableContentModel,
} from '../playable.model';

const { Text } = Typography;

type PlayableContentProps = {
  content: PlayableContentModel;
};

const PlayableContent = ({ content }: PlayableContentProps) => {
  const [showNextButton, setShowNextButton] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState<ContentNode[]>([]);
  const [currentNode, setCurrentNode] = useState<ContentNode | null>(null);
  const [contentTree, setContentTree] = useState<Map<
    string,
    ContentNode
  > | null>(null);
  useEffect(() => {
    const treeMap = new Map<string, ContentNode>();
    content.nodes.forEach((node) => treeMap.set(node.id, node));
    setSelectedNodes([]);
    setContentTree(treeMap);
    setCurrentNode(treeMap.get('root') || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (currentNode) {
      let nextNode;
      switch (currentNode.type) {
        case ContentNodeType.ROOT:
          nextNode = contentTree?.get(currentNode.nextNode || '');
          setCurrentNode(nextNode || null);
          break;
        case ContentNodeType.CONTENT:
          nextNode = contentTree?.get(currentNode.nextNode || '');
          setSelectedNodes([...selectedNodes, currentNode]);
          if (nextNode?.type === currentNode.type) {
            setShowNextButton(true);
          } else {
            setCurrentNode(nextNode || null);
          }
          break;
        case ContentNodeType.END:
          nextNode = contentTree?.get(currentNode.nextNode || '');

          setSelectedNodes([...selectedNodes, currentNode]);
          break;
        case ContentNodeType.SELECTION:
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNode]);

  const handleNext = (nextNodeId: string) => {
    const nextNode = contentTree?.get(nextNodeId);
    setShowNextButton(false);
    setCurrentNode(nextNode || null);
  };

  const replay = () => {
    setSelectedNodes([]);
    setCurrentNode(contentTree?.get('root') || null);
  };

  if (!contentTree) return <></>;
  return (
    <div>
      {selectedNodes.map((item, index) => (
        <div key={index} id={item.id}>
          <div
            className="content playable_content"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(item.content),
            }}
          ></div>
        </div>
      ))}
      <br />
      {showNextButton && (
        <Row justify="center">
          <Button
            onClick={() => handleNext(currentNode?.nextNode || '')}
            size="large"
          >
            Next
          </Button>
        </Row>
      )}
      {ContentNodeType.END === currentNode?.type && (
        <Row justify="center">
          <Button onClick={() => replay()} size="large">
            Replay
          </Button>
        </Row>
      )}
      {ContentNodeType.SELECTION === currentNode?.type && (
        <Row justify="center">
          <Card>
            <div className="option_dialog">
              <p style={{ textAlign: 'center' }}>
                <Text>
                  <QuestionCircleOutlined />
                </Text>
              </p>
              <p
                style={{ textAlign: 'center' }}
                className="content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(currentNode.content),
                }}
              ></p>
              <Row justify="space-around" gutter={[16, 16]}>
                {currentNode.options?.map((option, index) => (
                  <Col xs={24} sm={12} key={index}>
                    <div
                      className="option_button"
                      onClick={() => handleNext(option.nextNode || '')}
                    >
                      {option.content}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Card>
        </Row>
      )}
    </div>
  );
};

export default PlayableContent;

import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useWindowSize } from '../../hook/useWindowSize';

interface GridViewProps {
  items: any[];
  renderItem: Function;
}
const BreakPoints = [
  { name: 'xs', width: 0, col: 1 },
  { name: 'sm', width: 576, col: 2 },
  { name: 'md', width: 768, col: 2 },
  { name: 'lg', width: 992, col: 3 },
  { name: 'xl', width: 1200, col: 4 },
  { name: 'xxl', width: 1600, col: 4 },
];
const GridView = (props: GridViewProps) => {
  const { items, renderItem } = props;
  const { width } = useWindowSize();
  const [col, setCol] = useState(1);
  const [gridItems, setGridItems] = useState<any[][]>([]);

  useEffect(() => {
    const numberOfCol = getCol(width || window.innerWidth);
    if (numberOfCol !== col) {
      setCol(numberOfCol);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    const grid: any[][] = [];
    for (let i = 0; i < col; i++) {
      grid.push([]);
    }
    items.forEach((item, index) => {
      grid[index % col].push(item);
    });
    setGridItems(grid);
  }, [col, items]);

  const getCol = (screenWidth: number) => {
    const [breakPoint] = BreakPoints.filter(
      ({ width }) => width < screenWidth
    ).slice(-1);
    return breakPoint.col || 1;
  };

  return (
    <Row gutter={16}>
      {gridItems.map((items, index) => (
        <Col key={index} span={24 / col}>
          <Row gutter={[0, 16]}>
            {items.map((item, i) => (
              <Col key={i} span={24}>
                {renderItem(item)}
              </Col>
            ))}
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default GridView;
